<template>
  <div>
    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        <span>Area Wise Report</span>
        <el-form
          :inline="true"
          :model="area_id"
          class="float-right"
        >
          <el-form-item>
            <el-select
              v-model="area_id"
              placeholder="Select Area"
            >
              <el-option
                v-for="item in areas"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="generateReport"
            >
              Generate
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="report">
        <table class="table table-responsive">
          <thead class="">
            <tr>
              <th
                v-for="col in reportData.columns"
                :key="col"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in reportData.items"
              :key="rowIndex"
              :class="{ 'expanded-row': expandedRows.includes(rowIndex) }"
              @click="toggleRow(rowIndex)"
            >
              <template v-if="row.months.length > 0">
                <td
                  v-for="(month, monthName) in row.months"
                  :key="monthName"
                >
                  <div class="cell-content">
                    {{ month }}
                  </div>
                </td>
              </template>
              <template v-else>
                <td
                  v-for="(cell, colIndex) in getFilteredRow(row,'cell')"
                  :key="colIndex"
                  :style="{
                    maxWidth: clickedCell.row === rowIndex && clickedCell.col === colIndex ? '100%' : '160px',
                    textOverflow: clickedCell.row === rowIndex && clickedCell.col === colIndex ? 'unset' : 'ellipsis'
                  }"
                  @click="toggleCell(rowIndex, colIndex)"
                >
                  <div class="cell-content">
                    {{ cell }}
                  </div>
                </td>
                <td
                  v-for="(cell, colIndex) in getFilteredRow(row,'months')"
                  :key="colIndex"
                  :style="{
                    maxWidth: clickedCell.row === rowIndex && clickedCell.col === colIndex ? '100%' : '160px',
                    textOverflow: clickedCell.row === rowIndex && clickedCell.col === colIndex ? 'unset' : 'ellipsis'
                  }"
                  @click="toggleCell(rowIndex, colIndex)"
                >
                  <div class="cell-content">
                    {{ cell }}
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'AreaWiseReport',
  data() {
    return {
      area_id: null,
      isp_id: JSON.parse(localStorage.getItem('userData')),
      areas: [],
      reportData: {
        columns: [
          'Name',
          'Address',
          'Email',
          'Contact No.',
          'Username',
          'Speed',
          'Reg. Date',
          'Act.',
          'M/Bill',
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'June',
          'July',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        items: [
          {
            name: 'User first name last name 1',
            address: 'Mozaffar Monjil, Mollabari, Kazi Mozammal hoque Road.',
            email: 'user_1@gmail.com',
            contact: '01765326581',
            username: 'gpisp_ali_hossain_mollabari 1',
            speed: '1 Mbps',
            registrationDate: '1/Jan/19',
            status: 1,
            monthlyBill: 1200,
            months: {
              Jan: 1200,
              Feb: 1200,
              Mar: 1200,
              Apr: 1200,
              May: 1200,
              June: 1200,
              July: 1200,
              Aug: 1200,
              Sep: 1200,
              Oct: 1200,
              Nov: 1200,
              Dec: 1200,
            },
          },
          {
            name: 'User first name last name 2',
            address: 'Mozaffar Monjil, Mollabari, Kazi Mozammal hoque Road.',
            email: 'user_2@gmail.com',
            contact: '01765326582',
            username: 'gpisp_ali_hossain_mollabari 2',
            speed: '2 Mbps',
            registrationDate: '2/Jan/19',
            status: 1,
            monthlyBill: 1200,
            months: {
              Jan: 1200,
              Feb: 1200,
              Mar: 1200,
              Apr: 1200,
              May: 1200,
              June: 1200,
              July: 1200,
              Aug: 1200,
              Sep: 1200,
              Oct: 1200,
              Nov: 1200,
              Dec: 1200,
            },
          },
          {
            name: 'User first name last name 3',
            address: 'Mozaffar Monjil, Mollabari, Kazi Mozammal hoque Road.',
            email: 'user_3@gmail.com',
            contact: '01765326583',
            username: 'gpisp_ali_hossain_mollabari 3',
            speed: '3 Mbps',
            registrationDate: '3/Jan/19',
            status: 1,
            monthlyBill: 1200,
            months: {
              Jan: 1200,
              Feb: 1200,
              Mar: 1200,
              Apr: 1200,
              May: 1200,
              June: 1200,
              July: 1200,
              Aug: 1200,
              Sep: 1200,
              Oct: 1200,
              Nov: 1200,
              Dec: 1200,
            },
          },
          // ... more users can be added similarly
        ],
      },
      clickedCell: { row: null, col: null },
      expandedRows: [],
    }
  },
  mounted() {
    this.getAreaList()
  },
  methods: {
    getAreaList() {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL}/api/areas?isp_id=${this.isp_id.id}`)
        .then(res => {
          this.areas = res.data.data
        })
    },
    generateReport() {
      console.log('generateReport')
    },
    toggleRow(index) {
      const rowIndex = this.expandedRows.indexOf(index)
      console.log('rowindex', rowIndex)
      if (rowIndex === -1) {
        this.expandedRows.push(index)
      } else {
        this.expandedRows.splice(rowIndex, 1)
      }
    },
    toggleCell(row, col) {
      if (this.clickedCell.row === row && this.clickedCell.col === col) {
        this.clickedCell = { row: null, col: null } // Collapse if clicked again
      } else {
        this.clickedCell = { row, col } // Expand specific cell
      }
    },
    getFilteredRow(row, type) {
      if (type === 'months') {
        return row.months ? row.months : row
      }
      const { months, ...filteredRow } = row // Destructure to remove months
      return filteredRow // Return the object without the months key
    },

  },
}
</script>

<style scoped>
.table {
  font-size: 12px;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  padding: 4px;
  text-align: left;
  border: 1px solid #ddd;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
.table .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .truncate:hover {
  cursor: pointer;
}
.expanded-row {
  background-color: #f9f9f9;
}
.expanded-row .truncate1{
  background-color: #c3bebe;
  text-overflow: inherit;
  max-width: 100%;
}
.expanded .cell-content {
  white-space: normal;
  overflow: visible;
}
</style>
